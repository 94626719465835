export const headersMovements = [
    { text: "fecha", value: "date", width: '120px', fixed: true, },
    { text: "Movimiento", value: "type" },
    { text: "Cantidad", value: "quantity" },
    { text: "Producto", value: "product.description" },
    { text: "Referencia", value: "reference" },
    { text: "Usuario", value: "user.fullName" },
  ];

export const headersRequests = [
    { text: "Referencia", value: "ref" },
    { text: "Estado", value: "status" },
    { text: "Solicitante", value: "user.fullName" },
    { text: "Fecha", value: "requestDate" },
    { text: "Acciones", value: "actions" }
  ];
<template>
  <div>
    <v-card>
      <v-toolbar color="primary" dark class="mb-4 v-toolbar-header">
        <v-toolbar-title aling="center">Maestro de Categorías</v-toolbar-title>
      </v-toolbar>
      <v-card flat>
        <v-btn absolute dark fab top right color="success" @click="dialog = true">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-card-text>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table
            :headers="headers"
            :items="datos"
            :items-per-page="15"
            class="elevation-1 custom-font"
            :search="search"
            
          >
            <template v-slot:item.actions="{ item }" >
              <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon small class="mr-2" @click="deleteItem(item)">mdi-delete</v-icon>
              <!-- <v-icon small @click="showQR(item)">mdi-qrcode</v-icon> -->
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>          
    </v-card>

    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{formTitle}}</span>
          <v-spacer></v-spacer>
         
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="8">
                <v-text-field v-model="editedItem.name" label="Nombre" required outlined></v-text-field>
                
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="editedItem.sizeRef" label="Referencia de Talla" outlined></v-text-field>
              </v-col>
 
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="presave">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
      <v-dialog v-model="wqr" persistent width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">{{qr.nombre}}</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <span class="d-flex justify-center">
              <vue-qrcode :value="qr.nombre+'-'+qr.time+'@'+qr.id" :margin="1" :scale="12"/>
            </span>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="generatePrintQr">Descargar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="wqr = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>    
      <div>
        <vue-html2pdf
        :enable-download="true"
        :paginate-elements-by-height="1400"
        :filename="qr.nombre"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        ref="html2Pdf">
          <section slot="pdf-content">
            <hr>
            <br><br><br><br>
            <h1>Fullclean QR: {{qr.nombre}}</h1>
            <hr>
            <br><br><br><br>
            <div style="text-align: center; top: 20px;">
              <vue-qrcode :value="qr.nombre+'-'+qr.time+'@'+qr.id" :margin="1" :scale="5"/>
            </div>
          </section>
        </vue-html2pdf>
      </div>
      
  </div>
</template>
<script>
import axios from "axios"
import swal from "sweetalert"
import moment from "moment"
import api from "../conf/ruta-base"
import store from "../store"
import router from '../router'
import XLSX from 'xlsx'
import VueQrcode from 'vue-qrcode'
import VueHtml2pdf from 'vue-html2pdf'
import UserProfile from '../data';
moment.locale('es')
export default {
  name: "usuarios",
  components: {
    VueQrcode, VueHtml2pdf
  },
  data: () => ({
    apiPath: "categories", search: "", dialog: false, date: new Date().toISOString().substr(0, 10), wqr: false, api: api.api, qr: [],
    headers: [
      { text: "Nombre", align: "start", sortable: true, value: "name" },
      { text: "Referencia de Talla", value: "sizeRef" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    datos: [], user: UserProfile.getUserData(),
    selec: '', viewData: 'inline',
    instituciones:[], dialogofType: '', personSimple:[], editedIndex: -1,
    editedItem: { },
    defaultItem: { }
  }),
  mounted() {
    axios
      .get(`${api.api}/${this.apiPath}`)
      .then(r => {
        for (let i = 0; i < r.data.length; i++) {
          this.datos.push(r.data[i])
        }
      })
      .catch(error => {
        console.log(error);
      });
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Categoría" : "Modificar Categoría";
    }
  },
  watch:{
    wqr(value){
      if(value){
        this.viewData = 'none'
      }else{
        this.viewData = 'inline'
      }
    }
  },
  methods: {

    fechas(evento){
        this.dialogofType = evento;
        this.calendario = true;
    },

    fechasave(evento){
        let [year, month, day] = this.date.split('-')
        let date = day.padStart(2, '0') + '/' + month.padStart(2, '0') + '/' + year
        if(evento=='ingreso'){
          this.editedItem.fecingreso = date
          this.editedItem.fecha_ingreso = this.date
        }else if(evento=='nacimiento'){
          this.editedItem.fecnacimiento = date
          this.editedItem.fecha_nacimiento = this.date
        }
        this.dialogofType = ''
        this.calendario = false
        this.date = new Date().toISOString().substr(0, 10)
    },    

    editItem(item) {
      this.editedIndex = this.datos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    presave(){
      console.log(this.editedItem)
      let bandera = true
      let err = ''
        if(bandera && (this.editedItem.name.length == 0 )){
          bandera = false
          err = 'Debe completar los datos.'
        }

      if(bandera){
        this.save()
      }else{
        swal('',err,'error');
      }
      
    },

    save() {
      if (this.editedIndex > -1) {
        let index = this.editedIndex
        Object.assign(this.datos[this.editedIndex], this.editedItem)
        axios
          .patch(api.api + "/" + this.apiPath + '/' + this.editedItem.id, this.editedItem)
          .then(res => {
            this.toast(res.data.c, res.data.mensaje)
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        axios
          .post(api.api + "/" + this.apiPath, this.editedItem)
          .then(res => {
            this.datos.push(res.data)
            this.toast("success", "Categoria creada correctamente")
            
          })
          .catch(error => {
            console.log(error);
          });
      }
      this.close();
    },
    
    showQR(item){
      this.qr = item
      this.qr.time = Date.now();
      this.wqr = true
    },

    deleteItem (item) {
      const index = this.datos.indexOf(item)
      swal({text: "¿Seguro que desea eliminar?",icon: "warning", buttons: ["Cancelar", "Ok"],})
      .then(ok => {      
        if(ok){
          axios.delete(api.api + '/' + this.apiPath + '/' + item.id)
          .then(res => {
            
            this.datos.splice(index, 1)
            
            this.toast("success", "Categoria eliminada");
          })
          .catch(error => {
            console.log(error);
          });
        }
      })
    },

    restablecer(){
      axios
          .put(api.api + this.apiPath + '/reset/' + this.editedItem.id, this.editedItem)
          .then(res => {
            this.toast(res.data.c, res.data.mensaje);
          })
          .catch(error => {
            console.log(error);
          });
      this.close()
    },

    cargaMasiva(){
     router.push('/masivo')
    },

    toast(color, text) {
      var toast = { color, text };
      store.commit("TOAST", toast);
    },

    activate(id,estado){
      console.log(id,estado)
    },

    exportExcel(datosXLS) {
      let data = []
      for(let i=0;i<datosXLS.length;i++){
        let dtemp={
          codigo: datosXLS[i].codigo,
          cod_sup: 0,
          correo: datosXLS[i].correo,
          nombres: datosXLS[i].nombres,
          apellidos: datosXLS[i].apellidos,
          fecha_nacimiento: datosXLS[i].fecha_nacimiento,
          fecha_ingreso: datosXLS[i].fecha_ingreso,
          cargo: datosXLS[i].cargo,
          codigo_depto: datosXLS[i].codigo_depto,
          depto: datosXLS[i].depto,
          grupo: datosXLS[i].grupo,
          fono: datosXLS[i].fono,
        }
        data.push(dtemp)
      }
      let dataX = XLSX.utils.json_to_sheet(data)
      const workbook = XLSX.utils.book_new()
      const filename = 'empleadosEvaluacion'
      XLSX.utils.book_append_sheet(workbook, dataX, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },

    generatePrintQr(){
      this.$refs.html2Pdf.generatePdf()
    }

  }
};
</script>

<style>
.custom-font {
  font-family: 'Your Font Family', sans-serif;
  font-size: 16px; /* ajusta el tamaño de la fuente según tus necesidades */
  text-transform: uppercase;
}
</style>